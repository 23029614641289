import React from 'react';
import './App.css';
import Enrutador from './Rutas/Enrutador';
import AuthProvider from './Rutas/auth/AuthProvider';

function App() {
	return (
		<>
			<AuthProvider>
				<Enrutador />
			</AuthProvider>
		</>
	);
}

export default App;
