import { createContext, useState } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(sessionStorage.getItem("user"));
  const [GlobalLotes, setGlobalLotes] = useState([]);

  const handleLotes = (data) => {
    setGlobalLotes(data);
  };

  const login = (dataUser, token) => {
		setUser(dataUser);
    sessionStorage.setItem("token", token);
		sessionStorage.setItem("user", dataUser);
	};

	const logout = () => {
		setUser(null);
    sessionStorage.removeItem("token");
		sessionStorage.removeItem("user");
	};

  //OBJETO QUE SE VA A PASAR GLOBAL
  const contextValue = {
    user,
    login,
    handleLotes,
		GlobalLotes,
		logout
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
