import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import '../App.css';

export default function PrivateRoute() {

	//Getting the token
	const token = sessionStorage.getItem('token');

	//checking if the token exist
	return token ? (
		<div className="d-flex flex-wrap vh-100 contenedor-principal"
			style={{
				marginTop: '70px',
				overflow: 'auto',
				height: '1000px',
				paddingBottom: '100px',
			}}>
			<div className="children w-100">
				<Outlet />
			</div>
		</div>
	) : (
		<Navigate to={'/'} />
	);
}
