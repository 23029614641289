//IMPORTACIONES
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoaderPrincipal from '../Components/Loader/LoaderPrincipal/LoaderPrincipal';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
const ListadoLotes = lazy(() => import('../pages/Lotes/components/Listado/ListadoLotes'));
const Menu = lazy(() => import ('../Components/Menu/Menu'));
const Login = lazy(() => import ('../pages/Login/Login'));
const Admin = lazy(() => import ('../pages/Admin/Admin'));
const EmailsPersonalizados = lazy(() => import ('../pages/Admin/components/EmailsPersonalizados/EmailsPersonalizados'));
const RegistrarUsuarios = lazy(() => import ('../pages/Admin/components/RegistrarUsuarios/RegistrarUsuarios'));
const Clientes = lazy(() => import ('../pages/Clientes/Clientes'));
const FormImportantes = lazy(() => import ('../pages/Clientes/components/ClientesForm/FormImportantes'));
const FormResidenciales = lazy(() => import ('../pages/Clientes/components/ClientesForm/FormResidenciales'));
const UnicoCliente = lazy(() => import ('../pages/Clientes/components/UnicoCliente/UnicoCliente'));
const Lotes = lazy(() => import ('../pages/Lotes/Lotes'));
const Reporte = lazy(() => import ('../pages/Reporte/Reporte'));
const Averias = lazy(() => import ('../pages/Reporte/components/Averias/Averias'));
const Nodos = lazy(() => import ('../pages/Reporte/components/Nodos/Nodos'));

function Enrutador() {
	return (
		<Suspense fallback={<LoaderPrincipal />}>
			<Router>
				<Menu />
				<Routes>
					<Route element={<PrivateRoute />}>
						<Route path="/clientes/*" element={<Clientes />}>
							<Route path="importantes" element={<FormImportantes />} />
							<Route path="residenciales" element={<FormResidenciales />} />
							<Route path="cliente" element={<UnicoCliente />} />
							<Route path="*" element={<Navigate to={'importantes'} />} />
						</Route>

						<Route path="/reportes/*" element={<Reporte />}>
							<Route path="nodos" element={<Nodos />} />
							<Route path="averias" element={<Averias />} />
							<Route path="*" element={<Navigate to={'nodos'} />} />
						</Route>

						<Route path="/lotes/*" element={<Lotes />}>
							<Route path="listado" element={<ListadoLotes />} />
							<Route path="*" element={<Navigate to={'listado'} />} />
						</Route>

						<Route path="/admin/*" element={<Admin />}>
							<Route path="registro-usuarios" element={<RegistrarUsuarios />} />
							<Route path="emails-personalizados" element={<EmailsPersonalizados />} />
							<Route path="*" element={<Navigate to={'registro-usuarios'} />} />
						</Route>

					</Route>

					<Route exact path="/" element={
						<PublicRoute>
							<Login/>
						</PublicRoute>
					} />
				
					<Route path="/home" element={
						<PublicRoute>
							<Login/>
						</PublicRoute>
					} />

					<Route path="*" element={<h1>404 Not Found</h1>} />
				</Routes>
			</Router>
		</Suspense>
	);
}

export default Enrutador;
