import React from 'react';
import './LoaderPrincipal.css';

function LoaderPrincipal() {
	return (
		<div className='loader_container'>
			<span className="loader"></span>
		</div>
	);
}

export default LoaderPrincipal;
